<template>
  <section class="invoice-preview-wrapper">
    <b-row v-if="Object.keys(notification).length != 0" class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              style="
                padding: 3px;
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                width: 100%;
                color: red;
              "
            >
              <p>تتبع بوليصه الشحن . . . Waybills Tracking</p>
            </div>
          </b-card-body>
          <b-card-body v-if="notification" class="invoice-padding pt-0">
            <table class="table table-hover table-bordered">
              <tr>
                <th>{{ $t("Notification No") }}</th>
                <td># {{ notification.serial }}</td>
                <th>
                  {{ $t("goods_type") }}
                </th>
                <td>
                  {{
                    notification.goods_type ? notification.goods_type.name : ""
                  }}
                </td>
              </tr>
            </table>
          </b-card-body>
          <b-card-body v-if="notification" class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <b-table-lite
                responsive
                striped
                :items="notification.details"
                :fields="[
                  {
                    key: 'notification_type.name',
                    label: $t('notification_type'),
                  },
                  { key: 'car.board_number', label: $t('board_number') },
                  { key: 'driver.name_ar', label: $t('driver') },
                  { key: 'city_from.name', label: $t('from_city') },
                  { key: 'city_to.name', label: $t('to_city') },
                ]"
              >
              </b-table-lite>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />
          <GmapMap
            :center="center"
            :zoom="8"
            map-type-id="roadmap"
            style="width: 100%; height: 500px"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              @click="center = m.position"
            />
          </GmapMap>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BTable,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
} from "bootstrap-vue";
export default {
  data() {
    return {
      notification: {},
      center: {},
      markers: [],
      places: [],
    };
  },

  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BTable,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
  },

  computed: {},
  created() {
    this.$store.commit("START_LOADING");
    this.$http
      .get(`/crm/notification/${this.$route.params.id}/tracking`)
      .then((response) => {
        this.notification = response.data.notification;
        this.addMarker(response.data.tracking_data);
        this.$store.commit("STOP_LOADING");
      });
  },
  methods: {
    addMarker(currentPlace) {
      const marker = {
        lat: currentPlace.lat,
        lng: currentPlace.lng,
      };
      this.markers.push({ position: marker });
      this.places.push(currentPlace);
      this.center = marker;
    },
  },
};
</script>
