var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"invoice-preview-wrapper"},[(Object.keys(_vm.notification).length != 0)?_c('b-row',{staticClass:"invoice-preview"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticClass:"invoice-preview-card",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"invoice-padding pb-0"},[_c('div',{staticStyle:{"padding":"3px","text-align":"center","font-size":"20px","font-weight":"bold","width":"100%","color":"red"}},[_c('p',[_vm._v("تتبع بوليصه الشحن . . . Waybills Tracking")])])]),(_vm.notification)?_c('b-card-body',{staticClass:"invoice-padding pt-0"},[_c('table',{staticClass:"table table-hover table-bordered"},[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("Notification No")))]),_c('td',[_vm._v("# "+_vm._s(_vm.notification.serial))]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("goods_type"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.notification.goods_type ? _vm.notification.goods_type.name : "")+" ")])])])]):_vm._e(),(_vm.notification)?_c('b-card-body',{staticClass:"invoice-padding pt-0"},[_c('b-row',{staticClass:"invoice-spacing"},[_c('b-table-lite',{attrs:{"responsive":"","striped":"","items":_vm.notification.details,"fields":[
                {
                  key: 'notification_type.name',
                  label: _vm.$t('notification_type'),
                },
                { key: 'car.board_number', label: _vm.$t('board_number') },
                { key: 'driver.name_ar', label: _vm.$t('driver') },
                { key: 'city_from.name', label: _vm.$t('from_city') },
                { key: 'city_to.name', label: _vm.$t('to_city') } ]}})],1)],1):_vm._e(),_c('hr',{staticClass:"invoice-spacing"}),_c('GmapMap',{staticStyle:{"width":"100%","height":"500px"},attrs:{"center":_vm.center,"zoom":8,"map-type-id":"roadmap"}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position},on:{"click":function($event){_vm.center = m.position}}})}),1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }